import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Button, useTheme, Tooltip, Theme, Fade } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItemGroupItem } from 'interfaces/menu-items';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
// import useCredit from 'hooks/useCredit';
import ModalLogin from 'components/ModalLogin/index';
import { stageOmie } from 'services';
import useAnalytics from 'hooks/useAnalytics';

const STORAGE_ALERT_KEY = 'consulta_text_random';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    borderRadius: '0',
    background: 'none',
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    marginTop: '-1px !important',
    backgroundColor: theme.palette.secondary.main,
    // backgroundColor: 'red',
    color: theme.palette.primary[800],
    zIndex: '1099',
  },
  menuItem: {
    height: '70px',
    textTransform: 'none',
    transition: 'all 0.3s',
    padding: '6px 8px',
    heigth: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItemDestaque: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.secondary.dark,
    textDecorationThickness: '4px',
    textUnderlineOffset: '4px',
    animation: '$pulseColor 2s cubic-bezier(0.95, 1, 0.5, 1) 0s infinite alternate',
  },
  '@keyframes pulseColor': {
    '80%': {
      textUnderlineOffset: '4px',
    },
    '100%': {
      textUnderlineOffset: '10px',
    },
  },
  containerTop: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 18px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% + 45px)',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% + 50px)',
    },
  },
  
}));

interface MenuItemProps {
  item: MenuItemGroupItem;
  isActive: string | null;
  setActive: (url: string) => void;
}

function MenuItem(props: MenuItemProps): JSX.Element {
  const { item, isActive, setActive } = props;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  // const credit = useCredit();
  const auth = useAuth();

  const analytics = useAnalytics('header_button', 'portal');

  const [needLogin, setNeedLogin] = useState(false);

  const [href, setHref] = useState<string | undefined>(undefined);

  const classAnimated = item.highlight ? classes.menuItemDestaque : '';

  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();

    if (item.analytics === 'constraint_search') {
      if (window.location.href.indexOf(item.url) >= 0 && window.location.href.indexOf('planos') < 0) {
        return;
      }

      // obtem o texto atual no localstorage
      const storageKey = localStorage.getItem(STORAGE_ALERT_KEY);
      const text = storageKey !== null ? storageKey : '';

      if (text != '') {
        analytics.click(`header_button_${item.analytics}: ` + text);
      } else {
        analytics.click(`header_button_${item.analytics}`);
      }
    } else {
      analytics.click(`header_button_${item.analytics}`);
    }

    if (!auth.isAuthenticated && item.requiredLogin) {
      setHref(`${stageOmie}${item.url}`);
      setNeedLogin(true);
      return;
    } else {
      setHref(undefined);
    }

    if (item.external === true) {
      analytics.click(`open_${item.analytics}`);
      window.open(item.url, '_blank')?.focus();
    } else {
      navigate(item.url);
      setActive(item.url);
    }
  };

  const handleCloseModalLogin = (): void => {
    setNeedLogin(false);
  };

  const borderTopColor = isActive === item.url ? theme.palette.primary.main : theme.palette.primary[800];

  return (
    <>
      {needLogin && (
        <ModalLogin
          href={href}
          open={true}
          message={
            'Visualize rapidamente seu histórico financeiro, notas fiscais emitidas, 2ª via de boletos e muito mais!'
          }
          handleClose={handleCloseModalLogin}
        />
      )}
      <Grid item xs={'auto'}>
        <Tooltip
          title={item.tooltip}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 400 }}
        >
          <Button
            onClick={handleClick}
            className={classes.menuItem + ' ' + classAnimated}
            href={item.url}
            sx={{
              color: isActive === item.url ? theme.palette.primary.main : '#fff',
              borderTop: '6px solid' + borderTopColor,
            }}
          >
            {item.title}
          </Button>
          {/* <Link 
            underline="none"
            onClick={(e): void => handleClick(e, item)}
            variant={'body1'}
            className={classes.menuItem + ' ' + classAnimated}
            sx={{
              color: (isActive === item.url) ? theme.palette.primary.main : '#fff',
              borderTop: (isActive === item.url) ? '6px solid' + theme.palette.primary.main : 'none',
            }}
          >
            {item.title}
          </Link> */}
        </Tooltip>
      </Grid>
    </>
  );
}

export default function MenuRender(): JSX.Element | null {
  const [isActive, setActive] = useState<MenuItemGroupItem['url'] | null>(null);
  const auth = useAuth();
  const app = useApp();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      columnSpacing={{
        md: 1.95,
        lg: 5,
        xl: 6,
      }}
      className={classes.containerTop}
    >
      {app.menu.items.map((itemGroup) => {
        return itemGroup.children?.map((item: MenuItemGroupItem, index) => {
          if (!auth.isAuthenticated) {
            if (item.onlyAuthenticated !== false) return null;
          }

          if (item.onlyBeta === true) {
            if (auth.user?.app_ver !== 2) return null;
          }

          return <MenuItem key={item.url + '-' + index} item={item} isActive={isActive} setActive={setActive} />;
        });
      })}
    </Grid>
  );
}
