/* eslint-disable max-len */
import { api, apiExternal, apiErgon } from 'services';
import { Logger, CacheService } from 'services';
import { stageConnect } from 'services';
import { store } from 'store';
import { minutesToExpireDashboard, WAIT_SECOND_RETRY } from 'store/constant';
import { setLoadingDashboard } from 'store/reducer/dashboard';
import { setLoadingDashboardPainel } from 'store/reducer/dashboardPainel';
import { setLoadingNotification } from 'store/reducer/notification';
import { sleep } from 'utils';
import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import {
  ResponseError,
  PostQrCodeResponse,
  GetViewDocumentResponse,
  GetDashboardResponse,
  GetThingsFromProviderResponse,
  GetCompanyStoreResponse,
  GetApiErgonResponse,
  GetCnpjResponse,
  GetCepResponse,
  PostApiCreditErgon,
  GetCreditRequests,
  PostAPIContraProposta,
  GetAPIQuitacaoSimularPortal,
  GetCompanyProductsResponse,
  GetProductResponse,
  PostAPIContaCorrenteResponse,
  GetXlsxThingsFromProviderResponse,
  GetMyApps,
  GetUserSession,
  GetMyOrders,
  GetOrder,
  GetCart,
  PostShippingCalculateValue,
  GetMyOrdersFromCompany,
  GetThingsAllResponse,
  PostOrderCalculate,
  GetTreinamentosGravados,
  GetTreinamentosAoVivo,
  PostTreinamentosAoVivo,
  DeleteTreinamentosAoVivo,
  GetJoinTreinamentoAoVivo,
  PostPlayVideo,
  PostPinnedMyApps,
  GetInvites,
  PostResponseInvite,
  Invite,
  GetPaymentLink,
  PostPaymentLink,
  GetAPISimularRenegociacaoContrato,
  GetAPIDashBoard,
  ApiResponseStatus,
  ApiNewAppTrial,
  GetAPIPlanosConsultaRestricao,
  GetAPIHistoricoConsultaRestricao,
  PostAPIConsultaRestricaoPagamento,
  GetAPIConsultaRestricaoPorId,
} from 'interfaces/api';
import { CompanyCache, CompanyProduct, ErgonBank, MyApps, NewAppTrial } from 'interfaces';
import { AppHash, ICompanyContext } from 'context/CompanyContext';
import { GridSortItem } from '@mui/x-data-grid';
import { DocumentStakeholder } from 'context/DocumentContext';
import { stageApi } from 'services';
import { CartItem, ICart, ListCartProducts, CartOrder } from 'interfaces/cart';
import { OrderCustomer, OrderProvider } from 'interfaces/orders';
import { apiOmie } from 'services/api/omie';
import { Endereco, Usuario } from 'interfaces/consulta-restricao';


const reRaptchaConfig = (action: string): AxiosRequestConfig => {
  return { recaptcha: { useRecaptcha: true, action: action } };
};

export function isExpired(timestamp: number, minutesToExpire = minutesToExpireDashboard): boolean {
  return timestamp < new Date().getTime() - (1000 * 60 * minutesToExpire);
}

function responseErrorDefault(error: AxiosError, defaultMessage: string | null = null): ResponseError {
  try {
    Logger.error(error.toJSON());
  } catch (errorCatch) {
    Logger.error(error);
    Logger.error(errorCatch);
  }

  if (error.response) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = {
      ...(error.response.data as object),
      status: 'fatal_error',
    };
    if (!('message' in response)) {
      response.message = (defaultMessage) ? defaultMessage : 'Response error';
    }
    return response as ResponseError;
  }

  if (error.request) {
    return {
      status: 'fatal_error',
      message: (defaultMessage) ? defaultMessage : 'Request error',
    };
  }

  return {
    status: 'fatal_error',
    message: 'Unknown error',
  };

}

function responseErrorDefaultNew(error: AxiosError): AxiosResponse {
  try {
    Logger.error(error.toJSON());
  } catch (errorCatch) {
    Logger.error(error);
    Logger.error(errorCatch);
  }
  if (error.response) {
    return error.response;
  }

  if (error.request) {
    return {
      data: {
        status: 'fatal_error',
        message: 'Request error',
      },
      status: 999,
      statusText: 'error',
      ...(error && error.request && error.request.header && { header: error.request.header }),
    };
  }

  return {
    data: {
      status: 'fatal_error',
      message: 'Unknown error',
    },
    status: 998,
    statusText: 'error',
    ...(error && error.request && error.request.header && { header: error.request.header }),
  };

}

export const getUserSession = async (): Promise<GetUserSession | ResponseError> => {
  try {
    const response = await api.get<GetUserSession>('/session');
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getExternalDataByToken = async (
  date: string, token: string, params: string, async = false,
): Promise<AxiosResponse<GetViewDocumentResponse>> => {
  try {
    if (async) {
      const url = `/view?token=${token}&date=${date}&async=true${params ? '&' + params : ''}`;
      const response = await api.get(url, reRaptchaConfig('get_view_async'));
      return response;
    } else {
      const url = `/view?token=${token}&date=${date}${params ? '&' + params : ''}`;
      const response = await api.get(url, reRaptchaConfig('get_view_sync'));
      return response;
    }
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response;
  }
};

export const getCompanyStore = async (appHash: AppHash, isAsync = true): Promise<AxiosResponse<GetCompanyStoreResponse> | CompanyCache> => {

  const appHashParsed = appHash.match(/([\-])(?!.*\1)(.+)/i);

  appHash = (appHashParsed)
    ? appHashParsed[2]
    : appHash;

  if (!isAsync) {
    Logger.info(`Carregando store de ${appHash}`);

    const providerStorage = CacheService.getProvider(appHash);
    if (providerStorage) {
      if (!isExpired(providerStorage.timestamp, 2)) {
        Logger.info(`Usando cache local para provider [${appHash}]`);
        return providerStorage;
      }
      if (providerStorage.isLoading && providerStorage.code) {
        Logger.info(`Usando cache local para provider [${appHash}] enquanto o provider está atualizando`);
        return providerStorage;
      }
      if (providerStorage.isLoading && !providerStorage.code) {
        Logger.warning(`Aguardando o provider [${appHash}] atualizar`);
        await sleep(WAIT_SECOND_RETRY);
        return getCompanyStore(appHash);
      }
    } else {
      Logger.warning(`Não existe cache local para provider [${appHash}]`);
    }

  }

  CacheService.setLoadingProvider(appHash, true);

  Logger.info(`Chamando API para atualizar provider [${appHash}]`);

  try {
    const url = `/omie/store/${appHash}`;
    const response = await api.get<GetCompanyStoreResponse>(url);
    CacheService.setProvider(appHash, response.data);
    CacheService.setLoadingProvider(appHash, false);
    return response.data;
  } catch (error) {
    CacheService.removeProvider(appHash);
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const getExternalAPIErgon = async (prazo: number, valor: number, vencimento: number): Promise<GetApiErgonResponse> => {
  try {
    const url = `/api/installment/calc?prazo=${prazo}&valor=${valor}&diaVencimento=${vencimento}&origem=68`;
    const response = await apiErgon.get<GetApiErgonResponse>(url);
    return response.data;
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const getQrCode = async (hash: string): Promise<PostQrCodeResponse | ResponseError> => {
  try {
    const url = `https://connect${stageConnect}omie.com.br/pix/generate_qrcode`;
    const response = await apiExternal.post<PostQrCodeResponse>(url, { 'hash': hash });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getDashboardData = async (isAsync = false): Promise<GetDashboardResponse | ResponseError> => {

  if (!isAsync) {
    const dashboardStorage = CacheService.getDashboard();
    if (dashboardStorage) {
      if (dashboardStorage.status === 'success') {
        if (!isExpired(dashboardStorage.timestamp)) {
          Logger.info('Usando cache local para dashboard');
          return dashboardStorage;
        }
        const isLoadingDashboard = store.getState().dashboard.isLoading;
        if (isLoadingDashboard) {
          Logger.info('Usando cache local para dashboard enquanto o dashboard está atualizando');
          return dashboardStorage;
        }
      }
    }
  }

  if (store.getState().dashboard.isLoading) {
    Logger.warning('Aguardando o dashboard atualizar');
    await sleep(WAIT_SECOND_RETRY);
    return getDashboardData(isAsync);
  }

  store.dispatch(setLoadingDashboard(true));
  Logger.info('Chamando API para atualizar dashboard');

  try {
    const response = await api.get<GetDashboardResponse>('/omie/companies/things');
    await CacheService.setDashboard(response.data);
    Logger.info('Dashboard atualizado com sucesso');
    store.dispatch(setLoadingDashboard(false));
    return response.data;
  } catch (error) {
    await CacheService.clearDashboard();
    store.dispatch(setLoadingDashboard(false));
    return responseErrorDefault(error as AxiosError);
  }
};

export const getThingsFromProvider = async (
  provider: ICompanyContext,
  page: number,
  itemByPage: number,
  orderBy: GridSortItem,
  destinatario?: DocumentStakeholder | null,
): Promise<GetThingsFromProviderResponse | ResponseError> => {

  Logger.info(`Chamando API para atualizar documentos do provider [${provider.app_hash}]`);

  try {
    const url = `/omie/companies/things/${provider.code}`;
    const params = {
      offset: page * itemByPage,
      limit: itemByPage,
      orderBy: JSON.stringify(orderBy),
      filter: (destinatario) ? destinatario.cnpj_cpf : null,
    };
    const response = await api.get<GetThingsFromProviderResponse>(url, { params: params });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getXlsxDocuments = async (
  provider: ICompanyContext,
  orderBy: GridSortItem,
  destinatario?: DocumentStakeholder | null,
): Promise<GetXlsxThingsFromProviderResponse | ResponseError> => {

  Logger.info(`Chamando API para atualizar documentos do provider [${provider.app_hash}]`);

  try {
    const url = `/omie/companies/things/${provider.code}/download`;
    const params = {
      orderBy: JSON.stringify(orderBy),
      filter: (destinatario) ? destinatario.cnpj_cpf : null,
    };
    const response = await api.get<GetXlsxThingsFromProviderResponse>(url, { params: params });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getValidDocument = async (cnpj: string): Promise<GetCnpjResponse | ResponseError> => {
  try {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    const response = await api.get<GetCnpjResponse>(`/partners/cnpj?find=${cnpj}`);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getExternalAPIViaCep = async (cep: string): Promise<GetCepResponse | ResponseError> => {
  try {
    cep = cep.replace(/[^\d]+/g, '');
    const viaCepOmie = stageApi === 'dsv' ? `https://connect.dsv.omie.com.br/viacep/${cep}/json` : `https://connect.omie.com.br/viacep/${cep}/json`;
    const response = await apiExternal.get<GetCepResponse>(`${viaCepOmie}`);  
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postAPICreditErgon = async (payload: unknown): Promise<PostApiCreditErgon | ResponseError> => {
  try {
    const response = await api.post<PostApiCreditErgon>('/credit?action=ergonCredParceladoPortal', payload);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getAPIQuitacaoSimularPortal = async (payload: unknown): Promise<GetAPIQuitacaoSimularPortal | ResponseError> => {
  try {
    const response = await api.post<GetAPIQuitacaoSimularPortal>('/credit?action=ergonCredParceladoQuitacaoSimularPortal', payload);
    return response.data;
  } catch (error) {
    const responseError = (error as AxiosError).response;
    if (responseError) {
      const response = responseError.data as GetAPIQuitacaoSimularPortal;
      const message = (response.data.faultstring)
        ? response.data.faultstring.replace('ERROR:', '')
        : null;
      return responseErrorDefault(error as AxiosError, message);
    }
    return responseErrorDefault(error as AxiosError, 'Erro ao simular a quitação :(');
  }
};

export const postAPIQuitacaoSimularPortal = async (payload: unknown): Promise<GetAPIQuitacaoSimularPortal | ResponseError> => {
  try {
    const response = await api.post<GetAPIQuitacaoSimularPortal>('/credit?action=ergonCredParceladoQuitacaoSimularPortal', payload);
    return response.data;
  } catch (error) {
    const responseError = (error as AxiosError).response;
    if (responseError) {
      const response = responseError.data as GetAPIQuitacaoSimularPortal;
      const message = (response.data.faultstring)
        ? response.data.faultstring.replace('ERROR:', '')
        : null;
      return responseErrorDefault(error as AxiosError, message);
    }
    return responseErrorDefault(error as AxiosError, 'Erro ao simular a quitação :(');
  }
};

export const getCreditRequests = async (forceUpdate = false): Promise<GetCreditRequests | ResponseError> => {
  if (!forceUpdate) {
    const creditStorage = CacheService.getCredits();
    if (creditStorage) {
      if (!isExpired(creditStorage.timestamp, 15)) {
        Logger.info('Usando cache local para créditos');
        return creditStorage;
      }
      const creditsIsLoading = store.getState().notifications.isLoading;
      if (creditsIsLoading) {
        Logger.info('Usando cache local para créditos enquanto o crédito está atualizando');
        return creditStorage;
      }
    }
  }

  if (store.getState().notifications.isLoading && !forceUpdate) {
    Logger.warning('Aguardando o crédito atualizar');
    await sleep(WAIT_SECOND_RETRY);
    return getCreditRequests(forceUpdate);
  }

  if (!forceUpdate) Logger.warning('Não existe cache local para crédito');

  store.dispatch(setLoadingNotification(true));
  Logger.info('Chamando API para atualizar crédito');

  try {
    const response = await api.get<GetCreditRequests>('/credit');
    CacheService.setCredits(response.data);
    Logger.info('Notificação de crédito atualizada com sucesso');
    store.dispatch(setLoadingNotification(false));
    return await getCreditRequests(false);
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postAPIContraProposta = async (proposal: unknown): Promise<PostAPIContraProposta | ResponseError> => {
  try {
    const response = await api.post<PostAPIContraProposta>('/credit?action=ergonCredParceladoContrapropostaPortal', proposal);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getBankList = async (): Promise<ErgonBank[] | ResponseError> => {

  const bankListStorage: string | null = sessionStorage.getItem('bank_list');
  if (bankListStorage) return JSON.parse(bankListStorage) as ErgonBank[];

  try {
    const response = await apiErgon.get<ErgonBank[]>('/api/company/banklist');
    const bankList = response.data;
    sessionStorage.setItem('bank_list', JSON.stringify(bankList));
    return bankList;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getCompanyProducts = async (
  company: ICompanyContext, offset: number, limit = 10, query?: string, filter?: string, sort?: string,
): Promise<GetCompanyProductsResponse | ResponseError> => {

  Logger.info(`offset [${offset}] limit [${limit}]`);

  try {
    const url = (query)
      ? `/omie/products/${company.app_hash}?offset=${offset}&limit=${limit}&q=${query}`
      : `/omie/products/${company.app_hash}?offset=${offset}&limit=${limit}`;

    const urlFilter = filter ? `&filter=${filter}` : '';
    const urlSort = sort ? `&order=${sort}` : '';

    const urlFinal = `${url}${urlFilter}${urlSort}`;

    const response = await api.get<GetCompanyProductsResponse>(urlFinal);
    return response.data;
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const getProduct = async (
  appHash: ICompanyContext['app_hash'], productAlias: CompanyProduct['product_alias'],
): Promise<GetProductResponse | ResponseError> => {
  try {
    const url = `/omie/products/${appHash}/${productAlias}`;
    const response = await api.get<GetProductResponse>(url);
    return response.data;
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const postAPIContaCorrente = async (proposal: unknown): Promise<PostAPIContaCorrenteResponse | ResponseError> => {
  try {
    const response = await api.post<PostAPIContaCorrenteResponse>('/credit?action=ergonCredParceladoAlterarContacorrentePortal', proposal);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getMyApps = async (): Promise<GetMyApps | ResponseError> => {
  try {
    const response = await apiOmie.get<MyApps[]>('/apps/');

    return <GetMyApps>{
      status: 'success',
      data: response.data,
      timestamp: new Date().getTime(),
    };
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getMyAppsQuery = async (app: string): Promise<GetMyApps | ResponseError> => {
  try {
    const response = await apiOmie.get<MyApps[]>(`/apps/?q=${app}`);

    return <GetMyApps>{
      status: 'success',
      data: response.data,
      timestamp: new Date().getTime(),
    };
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getMyOrders = async (
  page: number,
  itemByPage: number,
  orderBy: GridSortItem,
  provider?: OrderProvider | null,
): Promise<GetMyOrders | ResponseError> => {
  try {
    const url = '/my-orders';
    const params = {
      offset: page * itemByPage,
      limit: itemByPage,
      orderBy: JSON.stringify(orderBy),
      filter: (provider) ? provider.app_hash : null,
    };
    const response = await api.get<GetMyOrders>(url, { params: params });
    return response.data;
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const getMyOrdersFromCompany = async (
  provider: ICompanyContext,
  page: number,
  itemByPage: number,
  orderBy: GridSortItem,
  customers?: OrderCustomer | null,
): Promise<GetMyOrdersFromCompany | ResponseError> => {
  try {
    const url = `/my-orders/company/${provider.code}`;
    const params = {
      offset: page * itemByPage,
      limit: itemByPage,
      orderBy: JSON.stringify(orderBy),
      filter: (customers) ? `${customers.name || ''}||${customers.document || ''}` : null,
    };
    const response = await api.get<GetMyOrdersFromCompany>(url, { params: params });
    return response.data;
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const getOrderById = async (date: string, orderId: string): Promise<GetOrder | ResponseError> => {
  try {
    const url = `/my-orders/${date}/${orderId}`;
    const response = await api.get<GetOrder>(url);
    return response.data;
  } catch (error) {
    const response = responseErrorDefaultNew(error as AxiosError);
    return response.data;
  }
};

export const getAllThings = async (
  page: number,
  itemByPage: number,
  orderBy: GridSortItem,
  destinatario?: DocumentStakeholder | null,
): Promise<GetThingsAllResponse | ResponseError> => {

  try {
    const url = '/omie/companies/things/all';
    const params = {
      offset: page * itemByPage,
      limit: itemByPage,
      orderBy: JSON.stringify(orderBy),
      filter: (destinatario) ? destinatario.cnpj_cpf : null,
    };
    const response = await api.get<GetThingsAllResponse>(url, { params: params });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getCart = async (): Promise<AxiosResponse<GetCart>> => {
  const url = 'cart';
  return api.get<GetCart>(url, reRaptchaConfig('get_cart'));
};

export const sendCart = async (cart: ICart): Promise<AxiosResponse> => {
  const url = 'cart';
  const body = {
    'cart': cart,
  };
  return api.put(url, body, reRaptchaConfig('put_cart'));
};

export const shippingCalculateValue = async (
  app_hash: string,
  cep: string,
  items: ListCartProducts[],
): Promise<PostShippingCalculateValue | ResponseError> => {
  try {
    const url = 'cart/calcShipping';
    const body = {
      'a': app_hash,
      'c': cep.replace('-', '').replace('.', ''),
      'p': { produtos: items },
    };
    const response = await api.post<PostShippingCalculateValue>(url, body, reRaptchaConfig('post_cart_calc_shipping'));
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postCartOrder = async (payload: CartOrder): Promise<PostOrderCalculate | ResponseError> => {
  try {
    const url = 'cart/order';

    const newMap = (payload.items as CartItem[]).map(item => {
      return {
        product_id: item.product_id,
        quantity: item.quantity,
      };
    });
    
    payload.items = newMap;

    const body = payload;
    const response = await api.post<PostOrderCalculate>(url, body, reRaptchaConfig('post_order'));
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};


export const getTreinamentosGravados = async (): Promise<GetTreinamentosGravados | ResponseError> => {
  try {
    const response = await apiOmie.get<GetTreinamentosGravados>('/training/recorded/');
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getTreinamentosAoVivo = async (): Promise<GetTreinamentosAoVivo | ResponseError> => {
  try {
    const response = await apiOmie.get<GetTreinamentosAoVivo>('/training/online/');
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getMyInvites = async (): Promise<GetInvites | ResponseError> => {
  try {
    const response = await apiOmie.get<GetInvites>('/users/me/invites');
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const responseInvite = async (accept: boolean, invite: Invite): Promise<PostResponseInvite | ResponseError> => {
  try {
    const payload = {
      'accept': accept,
      'app_hash': invite.app_hash,
      'invite_hash': invite.invite_hash,
    };
    const response = await apiOmie.post<PostResponseInvite>('/users/me/invites', payload);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postTreinamentoAoVivo = async (video: unknown): Promise<PostTreinamentosAoVivo | ResponseError> => {
  try {
    const response = await api.post<PostTreinamentosAoVivo>(`https://app${stageApi}.omie.com.br/api/portal/training/online/`, video);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const deleteTreinamentoAoVivo = async (roomId: string): Promise<DeleteTreinamentosAoVivo | ResponseError> => {
  try {
    const url = `https://app${stageApi}.omie.com.br/api/portal/training/online?room_id=${roomId}`;
    const response = await api.delete<DeleteTreinamentosAoVivo>(url);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getJoinTreinamentoAoVivo = async (video: unknown): Promise<GetJoinTreinamentoAoVivo | ResponseError> => {
  try {
    const response = await apiOmie.get<GetJoinTreinamentoAoVivo>(`/training/online/${video}`);
    return response.data;

  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postPlayVideo = async (video: unknown): Promise<PostPlayVideo | ResponseError> => {
  try {
    const response = await api.post<PostPlayVideo>(`https://app${stageApi}.omie.com.br/api/portal/training/recorded/`, video);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postPinned = async (pinned: unknown): Promise<PostPinnedMyApps | ResponseError> => {
  try {
    const response = await apiOmie.post<PostPinnedMyApps>('/users/me/favorites/apps', pinned);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getPaymentLink = async (date: string, id: string): Promise<GetPaymentLink | ResponseError> => {
  try {
    const response = await api.get<GetPaymentLink>(`/payment/${date}/${id}`, reRaptchaConfig('get_payment_link'));
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postPaymentLink = async (date: string, id: string, payload: any): Promise<PostPaymentLink | ResponseError> => {
  try {
    const response = await api.post<PostPaymentLink>(`/payment/${date}/${id}`, payload, reRaptchaConfig('post_payment_link'));
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const postAPIRenegociacaoCredito = async (payload: unknown): Promise<GetAPISimularRenegociacaoContrato | ResponseError> => {
  try {
    const response = await api.post<GetAPISimularRenegociacaoContrato>('/credit?action=ergonCredParceladoRenegociacaoPortal', payload);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError, 'Erro ao simular renegociação :(');
  }
};

export const getDashBoardPainel = async (date: string, isAsync = false): Promise<GetAPIDashBoard | ResponseError> => {

  if (!isAsync) {
    const dashboardStorage = CacheService.getDashboardPainel();
    if (dashboardStorage) {
      if (dashboardStorage.status === 'success') {
        if (!isExpired(dashboardStorage.timestamp)) {
          Logger.info('Usando cache local para o painel');
          return dashboardStorage;
        }
        const isLoadingDashboard = store.getState().dashboardPainel.isLoading;
        if (isLoadingDashboard) {
          Logger.info('Usando cache local para o painel enquanto o painel está atualizando');
          return dashboardStorage;
        }
      }
    }
  }

  if (store.getState().dashboardPainel.isLoading) {
    Logger.warning('Aguardando o painel atualizar');
    await sleep(WAIT_SECOND_RETRY);
    return getDashBoardPainel(date, isAsync);
  }

  store.dispatch(setLoadingDashboardPainel(true));
  Logger.info('Chamando API para atualizar painel');

  try {
    const response = await api.get<GetAPIDashBoard>(`/dashboard?period=${date}`);
    await CacheService.setDashboardPainel(response.data);
    Logger.info('Painel atualizado com sucesso');
    store.dispatch(setLoadingDashboardPainel(false));
    return response.data;
  } catch (error) {
    await CacheService.clearDashboardPainel();
    store.dispatch(setLoadingDashboardPainel(false));
    return responseErrorDefault(error as AxiosError);
  }
  
};

export const postCartReOrder = async (link: string): Promise<ApiResponseStatus | ResponseError> => {
  try {
    const response = await api.post<ApiResponseStatus>('/cart/reorder', { link_order: link });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError, 'Não foi possível colocar os produtos no carrinho :(');
  }
};

export const postAddNewAppTrial = async (appTrial: NewAppTrial): Promise<ApiNewAppTrial | ResponseError> => {
  try {
    const payload = {
      'company_nicename': appTrial.company_nicename,
      'billing_data': {
        'type': appTrial.isCpf ? 'Fisica' : 'Juridica',
        'document': appTrial.cpfCnpj,
      },
      'extra_info': {
        'company_objective': appTrial.objective,
      },
    };
    const response = await apiOmie.post<ApiNewAppTrial>('/apps/', payload);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError);
  }
};

export const getConsultaRestricao = async (document: string): Promise<GetAPIPlanosConsultaRestricao | ResponseError> => {
  try {
    const response = await api.get<GetAPIPlanosConsultaRestricao>(`/restriction/${document}`);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError, 'Falha ao obter os planos');
  }
};

export const postConsultaRestricao = async (document: string, type: string, user: Usuario, address: Endereco): Promise<PostAPIConsultaRestricaoPagamento | ResponseError> => {
  try {
    const response = await api.post<PostAPIConsultaRestricaoPagamento>('/restriction/order', { 
      document: document.replace(/\D/g, ''),
      type: type,
      user_data: {
        document: user.document.replace(/\D/g, ''),
        name: user.name,
        email: user.email,
        phone: user.phone.replace(/\D/g, ''),
        address: {
          street: address.address_street,
          number: address.address_number,
          district: address.address_district,
          extra: address.address_extra,
          zipcode: address.address_zipcode,
          city: address.address_city,
          state: address.address_state,
        },
      },
    });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError, 'Falha ao gerar o pagamento');
  }
};

export const getHistoricoConsultaRestricao = async (
  page: number,
  itemByPage: number,
  orderBy: GridSortItem,
): Promise<GetAPIHistoricoConsultaRestricao | ResponseError> => {

  try {
    const url = '/restriction/list';
    const params = {
      offset: page * itemByPage,
      limit: itemByPage,
      orderBy: JSON.stringify(orderBy),
    };
    const response = await api.get<GetAPIHistoricoConsultaRestricao>(url, { params: params });
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError, 'Falha ao obter as consultas');
  }
};

export const getConsultaRestricaoPorId = async (id: string): Promise<GetAPIConsultaRestricaoPorId | ResponseError> => {
  try {
    const response = await api.get<GetAPIConsultaRestricaoPorId>(`/restriction/order/${id}`);
    return response.data;
  } catch (error) {
    return responseErrorDefault(error as AxiosError, 'Falha ao obter a consulta');
  }
};