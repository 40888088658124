/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Menu, Divider, MenuItem, Grid, Typography, Avatar } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { LocalStorageService, stageOmie } from 'services';
import ItemMenu from './ItemMenu';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import useApp from 'hooks/useApp';
import useAnalytics from 'hooks/useAnalytics';


const MenuUser = () => {

  const auth = useAuth();
  const [userData, setUserData] = useState(auth.user);
  const location = useLocation();

  useEffect(() => {
    const userStorage = LocalStorageService.getUser();
    if (userStorage.email !== userData.email || userStorage.first_name !== userData.first_name) {
      setUserData(LocalStorageService.getUser());
    }
  }, [location.pathname]);

  return (
    <MenuItem>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item xs={'auto'}>
          <Avatar alt={userData.name} sx={{
            backgroundColor: (theme) => theme.palette.primary[800],
            color: (theme) => theme.palette.primary.main,
          }}>
            <FontAwesomeIcon iconName={'user-alt'} color={'inherit'} />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6" gutterBottom component="p" noWrap sx={{ margin: '5px 0 0 0', padding: 0, lineHeight: 1 }}>
            <strong>{userData.first_name} {userData.last_name}</strong>
          </Typography>
          <span style={{ margin: 0, padding: 0, lineHeight: 1, verticalAlign: 'middle !important' }}>
            <small>{userData.email}</small>
          </span>
        </Grid>
      </Grid>
    </MenuItem>
  );
};

const MenuHeader = ({ anchorEl, open, handleClose }) => {

  const auth = useAuth();
  const navigate = useNavigate();
  const app = useApp();
  const analytics = useAnalytics('header_button', app.module);

  const handleLogout = async () => {
    await analytics.click('logout');
    navigate('/logoff', { state: { redirectTo: window.location.href } });
  };

  const handleProfile = async () => {
    handleClose();
    analytics.click('my_profile');
    const url = `${stageOmie}/edit-profile/`;
    window.open(url, '_blank').focus();
  };

  const handleMyApps = async () => {
    handleClose();
    analytics.click('my_apps');
    navigate('/meus-aplicativos');
  };

  const handlePortal = async () => {
    handleClose();
    analytics.click('portal');
    navigate('/home');
  };

  const handleTest = async () => {
    handleClose();
    analytics.click('start_trial');
    const url = `${stageOmie}/trial`;
    window.open(url, '_blank').focus();
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        sx: {
          width: '300px !important',
        },
      }}
    >
      <MenuUser />
      <Divider />

      {
        (app.module === 'portal' && auth.user.myApps > 0) && (
          <ItemMenu label={'Meus Aplicativos'} onClick={handleMyApps} icon={'table-cells'} />
        )
      }
      {
        (app.module === 'portal' && auth.user.myApps === 0) && (
          <ItemMenu label={'Faça um teste agora do Omie ERP'} onClick={handleTest} icon={'rocket-launch'} bold />
        )
      }
      {
        (app.module === 'my-apps') && (
          <ItemMenu label={'Portal Omie'} onClick={handlePortal} icon={'globe'} />
        )
      }
      <ItemMenu label={'Meu Perfil'} onClick={handleProfile} icon={'user'} />

      <Divider />
      <ItemMenu label={'Sair'} onClick={handleLogout} icon={'sign-out'} />
    </Menu>
  );
};

export default MenuHeader;