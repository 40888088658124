import React from 'react';
import { MenuItem, ListItemText, ListItemIcon, Grid } from '@mui/material';
import FontAwesomeIcon from 'components/FontAwesomeIcon';
import { makeStyles } from '@mui/styles';
import 'components/PageNotFound/styles/PageNotFound.css';

const useStyles = makeStyles((theme) => ({
  itemIcon: {
    minWidth: '25px!important',
    textAlign: 'center',
  },
  itemIconBold: {
    color: theme.palette.secondary[800],
  },
  itemText: {
    margin: 0,
    padding: 0,
    lineHeight: 1,
  },
  itemTextBold: {
    fontWeight: '900 !important',
    color: theme.palette.primary[800],
  },
}));

const ItemMenu = ({ label, onClick, icon, bold }) => {

  const classes = useStyles();

  const iconItem = (icon)
    ? (
      <ListItemIcon  className={`${classes.itemIcon} ${(bold) ? classes.itemIconBold : ''}`}>
        <FontAwesomeIcon iconName={icon} color={'inherit'} />
      </ListItemIcon>
    )
    : null;

  return (
      <MenuItem onClick={onClick}>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={0}>
          <Grid item xs={'auto'}>
            {iconItem}
          </Grid>
          <Grid item>
            <ListItemText>
              <p className={`${classes.itemText} ${(bold) ? 'not-found-link' : ''}`}>{label}</p>
            </ListItemText>
          </Grid>
        </Grid>
      </MenuItem>
  );
};

export default ItemMenu;